<template>
  <v-btn v-if="avaliacao"
         link
         :to="`/avaliacao/${avaliacao.key}`"
         color="primary"
         min-width="171px"
         class="mt-3 normal-btn-text rounded-tag-small elevation-0">
    Avaliar compra
  </v-btn>
</template>

<script>
import CompraService from '../compras-service';

export default {
  props: {
    compra: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avaliacao: null,
    };
  },
  methods: {
    getAvaliacaoCompra() {
      CompraService.getAvaliacaoCompra(this.compra.id)
        .then(({ data }) => {
          this.avaliacao = data;
        });
    },
  },
  created() {
    this.getAvaliacaoCompra();
  },
};
</script>
