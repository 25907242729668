<template>
  <px-lateral-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn color="error"
             min-width="171px"
             :class="btnClass"
             :small="small"
             :large="large"
             :block="block"
             v-on="on">
        Cancelar pedido
      </v-btn>
    </template>
    <template v-slot:title>
      Cancelar pedido
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            class="required"
            label="Motivo do cancelamento"
            counter="1500"
            rows="4"
            v-model="motivoRecusa"
            :error="$v.motivoRecusa.$error"
            @blur="$v.motivoRecusa.$touch()"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close">
        Fechar
      </v-btn>
      <v-btn color="primary" :loading="loading" @click="onClickCancelar">
        Enviar cancelamento
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompraService from '../compras-service';

export default {
  props: {
    compra: Object,
    btnClass: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      motivoRecusa: null,
    };
  },
  methods: {
    onClickCancelar() {
      if (this.validateForm()) {
        return;
      }
      this.cancelar();
    },
    cancelar() {
      this.loading = true;
      CompraService.cancelar(this.compra.id, this.buildCompra())
        .then(() => {
          this.$emit('movimentar');
          this.$toast('Compra cancelada com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    buildCompra() {
      return {
        id: this.compra.id,
        motivoRecusa: this.motivoRecusa,
      };
    },
  },
  validations: {
    motivoRecusa: {
      required,
    },
  },
};
</script>
