<template>
  <div>
    <div v-if="isFromCarrinho">
      <v-row class="mb-3">
        <v-col cols="12" sm="6">
          <v-btn large color="primary" link to="/carrinho"
                 class="rounded-card-small normal-btn-text">
            Voltar para o carrinho
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-btn outlined large link to="/home" class="rounded-card-small normal-btn-text">
            Ir para home
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert
            class="rounded-card-small"
            color="primary">
            <div class="font-weight-bold mt-4 mb-4 white--text d-flex justify-center">
              A forma de pagamento e aprovação da compra são de responsabilidade do fornecedor.
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" sm="8" class="body-1 d-flex align-center">
        <div>
          Pedido <b>nº {{ compra.id }}</b> | Pedido em <b>{{ compra.dataCompra | date }}</b>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="text-right">
        <px-situacao-compra-marketplace-text :situacao="compra.situacao"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    compra: {
      type: Object,
      required: true,
    },
    isFromCarrinho: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
