<template>
  <div>
    <div v-if="isModoFreteMelhorEnvio">
      <v-skeleton-loader
        :loading="loading"
        type="list-item-two-line"
      >
        <div>
          <div class="body-2">
            Confira os pacotes que serão entregues para sua empresa:
          </div>
          <v-row no-gutters v-if="statusFrete && statusFrete.tracking">
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item v-for="track in statusFrete.tracking" :key="track.id">
                  <v-list-item-content>
                    <v-img class="logo-transportadora"
                           :src="statusFrete.frete.picture"/>
                  </v-list-item-content>
                  <v-list-item-content>
                    {{ statusFrete.frete.name }}
                  </v-list-item-content>
                  <v-list-item-content>
                    <px-situacao-composicao-pacote small :situacao="track.situacao"/>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn small
                           class="normal-btn-text rounded-tag-small elevation-0"
                           :disabled="isPacotePendente(track)"
                           :href="linkRastreio(track)"
                           target="_blank">
                      Rastrear
                      <v-icon right small>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import ComprasService from '@/modules/compras/compras-service';
import { SituacaoComposicaoPacote } from 'px-business-components';

const MELHOR_ENVIO_RASTREIO = 'https://app.melhorrastreio.com.br/app/melhorenvio/';

export default {
  props: {
    pacote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      statusFrete: null,
    };
  },
  computed: {
    isModoFreteMelhorEnvio() {
      return this.pacote.frete.modoFrete === 'MELHOR_ENVIO';
    },
    isPacotePendente() {
      return pacote => [SituacaoComposicaoPacote.keys.PENDENTE.key,
        SituacaoComposicaoPacote.keys.AGUARDANDO_APROVACAO.key].includes(pacote.situacao);
    },
    linkRastreio() {
      return pacote => `${MELHOR_ENVIO_RASTREIO}${pacote.protocol}`;
    },
  },
  methods: {
    getStatusFrete() {
      this.loading = true;
      ComprasService.getStatusFrete(this.pacote.id)
        .then(({ data }) => {
          this.statusFrete = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.isModoFreteMelhorEnvio) {
      this.getStatusFrete();
    }
  },
};
</script>
